<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-organization-list' }">Organizations</router-link>
                    <template v-if="organization">
                    &gt;
                    <router-link :to="{ name: 'organization-dashboard', params: { organizationId: this.$route.params.organizationId } }">{{ organizationName }}</router-link>
                    </template>
                </p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <v-card class="pa-0 mt-0">
                        <v-toolbar dense flat color="green darken-2" dark>
                            <v-toolbar-title>Registration Mode</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                            <p class="mb-0 mt-2">
                                There following modes are available to manage registration (account sign up) for your storefront:
                            </p>
                            <ul>
                                <li><strong>Connect</strong> - registration is managed by your own website, and when you redirect customers to Unicorn Springs you identify the customer account and user; this entails more development work on your website but gives you complete control over the sign up and sign in process for your customers</li>
                                <li><strong>Open</strong> - users can create an account at Unicorn Springs and start using it immediately; this is the default option and saves you a lot of time and effort compared to the other options because Unicorn Springs has excellent customer account and user management built-in</li>
                                <!-- <li><strong>Restricted</strong> - account creation is by invitation only; only users with an invitation can create an account</li> -->
                                <!-- <li><strong>Moderated</strong> - users can request an account but an administrator must approve it before it can be used</li> -->
                                <li><strong>Closed</strong> - users cannot create a new account; this is useful when you are testing your service or resolving an issue</li>
                            </ul>

                            <template v-if="isViewReady">
                                <p class="mb-0 pb-0 mt-6">
                                    Registration mode: <strong>{{ registrationModeDisplay }}</strong>
                                    <v-btn icon color="green darken-2" @click="dialogEditRegistrationMode = true">
                                        <font-awesome-icon :icon="['fas', 'pencil-alt']"></font-awesome-icon>
                                    </v-btn>
                                </p>
                                <p class="mb-0 pb-0 mt-2" v-if="registrationMode === 'connect'">
                                    <EditableText :value="registrationExternalURL" label="Registration URL" @input="saveRegistrationExternalURL" dense/>
                                </p>

                                <p v-if="registrationMode !== 'connect'">
                                    Custom registration URL: <span v-if="registrationCustomURL"><a :href="registrationCustomURL" target="_blank">{{ registrationCustomURL }}</a></span><span v-else>Not configured</span>
                                </p>
                            </template>
                        </v-card-text>
                    </v-card>
                    <v-dialog v-model="dialogEditRegistrationMode" max-width="600">
                        <v-card class="pa-0" max-width="600">
                            <v-toolbar dense flat color="white">
                                <v-toolbar-title class="green--text text--darken-2">Set the registration mode</v-toolbar-title>
                            </v-toolbar>
                            <!-- <v-divider class="mx-5 mb-10"></v-divider> -->
                            <v-card-text>
                                <p>This change will take effect immediately. Please ensure your website is ready.</p>
                            </v-card-text>
                            <v-form @submit.prevent="editRegistrationMode" @keyup.enter.native="editRegistrationMode" class="px-5 mt-5">
                                <v-select v-model="editableRegistrationMode" :items="registrationModeChoices" outlined dense>
                                </v-select>
                            </v-form>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn elevation="4" class="green darken-2 white--text" @click="editRegistrationMode" :disabled="!isEditRegistrationModeFormComplete">
                                    Save
                                </v-btn>
                                <v-btn text class="grey--text" @click="dialogEditRegistrationMode = false">Cancel</v-btn>
                                <v-spacer/>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import EditableText from '@/components/EditableText.vue';

export default {
    components: {
        EditableText,
    },
    data: () => ({
        organization: null,
        registrationMode: null,
        registrationExternalURL: null,
        registrationCustomURL: null, // for 'open', 'restricted', 'moderated', and 'closed' registration (everything except 'connect')
        error: null,
        isViewReady: false,
        registrationModeChoices: [
            { text: 'Connect', value: 'connect' },
            { text: 'Open', value: 'open' },
            // { text: 'Restricted', value: 'restricted' },
            // { text: 'Moderated', value: 'moderated' },
            { text: 'Closed', value: 'closed' },
        ],
        dialogEditRegistrationMode: false,
        editableRegistrationMode: null,
        submitFormTimestamp: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
        }),
        organizationName() {
            return this.organization?.name ?? 'Unknown';
        },
        isViewReady() {
            return this.organization !== null;
        },
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        isEditRegistrationModeFormComplete() {
            // TODO: if editableRegistrationMode === 'sso' and organization doesn't have the enterprise add-on, we need to show a dialog that says they need that add-on to use sso, and a button to purchase it; if they cancel the dialog, the editable registration mode should revert to its previous value
            return this.editableRegistrationMode;
        },
        registrationModeDisplay() {
            if (this.registrationMode) {
                const choice = this.registrationModeChoices.find((item) => item.value === this.registrationMode);
                if (choice) {
                    return choice.text;
                }
            }
            return 'Set the registration mode';
        },
    },
    watch: {
        dialogEditRegistrationMode(newValue) {
            if (newValue) {
                this.editableRegistrationMode = this.registrationMode;
            }
        },
    },
    methods: {
        async loadOrganization() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadOrganization: true });
                const response = await this.$client.organization(this.$route.params.organizationId).currentOrganization.get();
                console.log(`organization/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.organization = response;
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load organization', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadOrganization: false });
            }
        },
        async loadSetting(name) {
            try {
                this.error = false;
                this.$store.commit('loading', { loadSetting: true });
                const response = await this.$client.organization(this.$route.params.organizationId).setting.get({ name });
                console.log(`organization/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    return response.content;
                }
                return null;
            } catch (err) {
                console.error('failed to load organization', err);
                this.error = true;
                return null;
            } finally {
                this.$store.commit('loading', { loadSetting: false });
            }
        },
        async loadRegistrationMode() {
            this.registrationMode = await this.loadSetting('registration_mode');
            if (this.registrationMode === 'connect') {
                await this.loadRegistrationExternalURL();
            } else {
                await this.loadRegistrationCustomURL();
            }
        },
        async loadRegistrationExternalURL() {
            this.registrationExternalURL = await this.loadSetting('registration_external_url');
        },
        async loadRegistrationCustomURL() {
            this.registrationCustomURL = await this.loadSetting('registration_custom_url');
        },
        async editRegistrationMode() {
            try {
                if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                    return;
                }
                this.submitFormTimestamp = Date.now();
                this.error = false;
                this.$store.commit('loading', { editRegistrationMode: true });
                const response = await this.$client.organization(this.$route.params.organizationId).setting.edit({ name: 'registration_mode' }, { content: this.editableRegistrationMode });
                console.log(`editRegistrationMode: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.dialogEditRegistrationMode = false;
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    this.loadRegistrationMode();
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit registration mode' });
                }
            } catch (err) {
                console.error('failed to edit registration mode', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit registration mode' });
            } finally {
                this.$store.commit('loading', { editRegistrationMode: false });
            }
        },
        async saveRegistrationExternalURL(newValue) {
            try {
                if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                    return;
                }
                this.submitFormTimestamp = Date.now();
                this.error = false;
                this.$store.commit('loading', { saveRegistrationExternalURL: true });
                const response = await this.$client.organization(this.$route.params.organizationId).setting.edit({ name: 'registration_external_url' }, { content: newValue });
                console.log(`saveRegistrationExternalURL: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.registrationExternalURL = newValue;
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit registration external URL' });
                }
            } catch (err) {
                console.error('failed to edit registration external URL', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit registration external URL' });
            } finally {
                this.$store.commit('loading', { saveRegistrationExternalURL: false });
            }
        },
        async init() {
            await this.loadRegistrationMode();
            this.isViewReady = true;
        },
    },
    mounted() {
        this.loadOrganization(); // TODO: remove when we have organization maintained by vuex store.js and app.vue
        this.init();
    },
};
</script>
